.auto-table-layout table {
  table-layout: auto !important;
}

.table-danger-legend-box {
  width: 20px;
  height: 20px;
  background-color: #F4C2D4;
  display: inline-block;
  vertical-align: top;
}

.table-danger-legend-text {
  height: 20px;
  display: inline-block;
  margin-left: 5px;
}